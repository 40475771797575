import './index.scss';
import NoahKuhnLogoSVG from '../../assets/images/NOAH_KUHN_logo.svg'

import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseUser, faBusinessTime, faLaptopCode, faSquarePhone } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => (
    <div className='nav-bar'>
        <Link className='logo' to='/'>
            <img src={NoahKuhnLogoSVG} alt="Logo: NOAH KUHN (they/them)" />
        </Link>
        <nav>
            <NavLink to="/" title="Home">
                <FontAwesomeIcon icon={faHouseUser} color="#fcfbfd" />
            </NavLink>
            <NavLink to="/history" title="Work History">
                <FontAwesomeIcon icon={faBusinessTime} color="#fcfbfd" />
            </NavLink>
            <NavLink to="/projects" title="Projects">
                <FontAwesomeIcon icon={faLaptopCode} color="#fcfbfd" />
            </NavLink>
            <NavLink to="/contact" title="Contact">
                <FontAwesomeIcon icon={faSquarePhone} color="#fcfbfd" />
            </NavLink>
        </nav>
    </div>
);

export default Navbar;