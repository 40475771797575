import './index.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faItchIo, faGithub, faGitlab } from '@fortawesome/free-brands-svg-icons';

const Footer = () => (
    <footer>
        <div className="footer-text">
            <p>
                The fonts used throughout this website are <a title="Montserrat on Google Fonts" target="_blank" rel="noreferrer" href="https://fonts.google.com/specimen/Montserrat">Montserrat Black Italic</a> for big text and <a title="Atkinson Hyperlegible on Google Fonts" target="_blank" rel="noreferrer" href="https://fonts.google.com/specimen/Atkinson+Hyperlegible">Atkinson Hyperlegible</a> for smaller text.
                Icons were used from <a title="Font Awesome Website" target="_blank" rel="noreferrer" href="https://fontawesome.com/">Font Awesome</a>'s free selection. <br />
                This website was developed with love in <a title="React Website" target="_blank" rel="noreferrer" href="https://react.dev/">React</a> by and for Noah Kuhn. Copyright &copy; 2023 Noah Kuhn
            </p>
        </div>
        <div className="footer-links">
            <ul>
                <li>
                    <a title="Email" target="_blank" rel="noreferrer" href="mailto:kuhnnoah01@gmail.com">
                        <FontAwesomeIcon icon={faAt} color="#fcfbfd" />
                    </a>
                </li>
                <li>
                    <a title="LinkedIn" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/noah-kuhn/">
                        <FontAwesomeIcon icon={faLinkedin} color="#fcfbfd" />
                    </a>
                </li>
                <li>
                    <a title="itch.io" target="_blank" rel="noreferrer" href="https://noahkuhn.itch.io/">
                        <FontAwesomeIcon icon={faItchIo} color="#fcfbfd" />
                    </a>
                </li>
                <li>
                    <a title="GitHub" target="_blank" rel="noreferrer" href="https://github.com/noah-kuhn/">
                        <FontAwesomeIcon icon={faGithub} color="#fcfbfd" />
                    </a>
                </li>
                <li>
                    <a title="GitLab" target="_blank" rel="noreferrer" href="https://gitlab.com/nk_ultra">
                        <FontAwesomeIcon icon={faGitlab} color="#fcfbfd" />
                    </a>
                </li>
            </ul>
        </div>
    </footer>
);

export default Footer;