import DropdownSection from '../DropdownSection';
import Header from '../Header';

import { HeadingLevel, Heading } from '@ariakit/react';

export default function History () {
    return (<div>
        <HeadingLevel>
            <Header text="work history" />
            <div className="main-content">
                <HeadingLevel>
                    <Heading>Contracting for Indie Games</Heading>
                    <p>
                        Since January 2023, I have been contracting on various indie game projects. On each of these, I have served as a programmer, often
                        taking on the responsibility of architecting gameplay systems, designing key functionality, and collaborating with designers and
                        artists.
                    </p>
                    <HeadingLevel>
                        <DropdownSection sectionTitle="Contract Quest Programmer, Party for Introverts" subtitle="Jul&ndash;Oct 2023">
                            <p>
                                At <a title="Party for Introverts Website" target="_blank" rel="noreferrer" href="https://www.partyforintroverts.com">Party for Introverts</a>,
                                I am responsible for implementing the base logic for 8 quests for their upcoming 2D narrative RPG,&nbsp;
                                <a title="Cabernet on Steam" target="_blank" rel="noreferrer" href="https://store.steampowered.com/app/2401410/Cabernet/">Cabernet</a>.
                                <br />
                                Primary responsibilities include:
                            </p>
                            <ul>
                                <li>Importing and integrating dialogue from scripts into the game to create immersive storytelling experiences</li>
                                <li>Utilizing visual scripting in Unity to construct complex quest logic, encompassing branching dialogue options,
                                    player-driven choices, and dynamic quest progression</li>
                                <li>Implementing in-game variables and player statistics, updating them based on player decisions and quest outcomes</li>
                                <li>Testing, debugging, and seeking feedback on each quest to ensure the delivery of a logic-stable base version that
                                    guarantees a solid foundation for subsequent development and polish from the team</li>
                            </ul>
                            <p>
                                During my tenure, I've used Unity, visual scripting, C#, Yarn Spinner, and Plastic SCM. I also practice rapid development:
                                I consistently deliver logic-stable quest prototypes within one-week timelines to enable swift iteration and refinement by
                                the larger team.
                            </p>
                        </DropdownSection>
                    </HeadingLevel>
                    <HeadingLevel>
                        <DropdownSection sectionTitle="Game Engineering Intern, Retora Games" subtitle="May&ndash;Jul 2023">
                            <p>
                                At <a title="Retora Games Website" target="_blank" rel="noreferrer" href="https://www.retoragames.com">Retora Games</a>, I
                                played a pivotal role in the development of <a title="TBDNE" target="_blank" rel="noreferrer" href="https://www.tbdne.com/">TBDNE</a>,
                                a zero-to-one daily web game aiming to make public the current state and ability of generative AI to the broader community.
                            </p>
                            <ul>
                                <li>Spearheaded the development of all gameplay logic systems using C#, including scoring mechanism, timer, state
                                    transitions between gameplay and feedback phases, and clipboard sharing functionality</li>
                                <li>Created JavaScript plugins to enhance the game's browser compatibility and overcome challenges posed by certain
                                    browser restrictions</li>
                                <li>Crafted C# editor scripts to streamline development by automating various tasks, including generating puzzle
                                    data from CSV files and matching puzzles together into rough sets&mdash;both of which eliminated manual data entry
                                    and boosted developer efficiency</li>
                                <li>Tested on a variety of devices and browsers to ensure consistent performance on both web and mobile</li>
                                <li>With a team of two other interns, delivered a working vertical slice of the game with over 150 unique puzzles
                                    within a 10-week development cycle.</li>
                            </ul>
                            <p>
                                In this role, I used Unity, C#, JavaScript, and Git.
                            </p>
                        </DropdownSection>
                    </HeadingLevel>
                    <HeadingLevel>
                        <DropdownSection sectionTitle="Programmer & Game Designer, Texas Immersive Institute" subtitle="Jan&ndash;May 2023">
                            <p>
                                With the <a title="Texas Immersive Institute Website" target="_blank" rel="noreferrer" href="https://immersive.moody.utexas.edu">Texas Immersive Institute</a> (TXI),
                                I acted as the only dedicated programmer on the development team
                                for <a title="Space Shapers project overview on the TXI website" target="_blank" rel="noreferrer" href="https://immersive.moody.utexas.edu/projects/space-shapers">Space Shapers</a>,
                                a mobile edutainment game for our client, <a title="Austin PBS website" target="_blank" rel="noreferrer" href="https://austinpbs.org/">Austin PBS</a>.
                            </p>
                            <ul>
                                <li>Developed key systems including file parsing, localization, and scriptable storybook sequences in C# and Unity</li>
                                <li>Designed and implemented a modular class hierarchy to seamlessly integrate diverse minigames into the game's
                                    overarching storybook using a standardized API</li>
                                <li>Collaborated closely with designers to overcome scoping issues while still meeting client expectations</li>
                                <li>Delivered a minimum viable product to Austin PBS within a 14-week timeline</li>
                            </ul>
                            <p>
                                While working with TXI and Austin PBS, I used Unity, C#, and Git.
                            </p>
                        </DropdownSection>
                    </HeadingLevel>
                </HeadingLevel>
                <HeadingLevel>
                    <Heading>Software Engineering Internships with Meta</Heading>
                    <p>
                        Over the summer of 2021, I had the opportunity to work
                        at <a title="Meta Website" target="_blank" rel="noreferrer" href="https://about.meta.com">Meta</a> as a Software Engineering
                        Intern&mdash;then, I was thrilled to return for the following summer. I tried my hand at two different areas during these internships:
                        for the first, I  contributed to a feature that was rolled out on the Facebook app for actual users to see, and for the second, I
                        worked on an internal tool used by other world-class developers company-wide.
                    </p>
                    <HeadingLevel>
                        <DropdownSection sectionTitle="Software Engineering Intern, Meta" subtitle="May&ndash;Aug 2022">
                            <p>
                                For my second internship at <a title="Meta Website" target="_blank" rel="noreferrer" href="https://about.meta.com">Meta</a>,
                                I contributed to the development of an internal job-monitoring tool that served as a linchpin for ensuring the smooth execution
                                of various distributed system jobs across the company.
                            </p>
                            <ul>
                                <li>Programmed in Hack to enhance the tool's functionality, focusing on exception handling and automatic issue
                                    detection</li>
                                <li>Improved the exception-handling framework, ensuring that jobs could smartly reprioritize themselves based on status, and
                                    that job owners would receive immediate, actionable notifications upon a job timing out or encountering errors</li>
                                <li>Refactored various sections of the tool's codebase, fostering extensibility and maintainability for future
                                    enhancements</li>
                                <li>Increased developer productivity by making job failures more visible to developers and reducing the need to seek out
                                    information from our team when a job failed</li>
                            </ul>
                            <p>
                                During this internship, I worked in <a title="Hack Language Website" target="_blank" rel="noreferrer" href="https://hacklang.org/">Hack</a>,
                                using <a title="Mercurial Website" target="_blank" rel="noreferrer" href="https://www.mercurial-scm.org/">Mercurial</a> for
                                source control.
                            </p>
                        </DropdownSection>
                        
                    </HeadingLevel>
                    <HeadingLevel>
                        <DropdownSection sectionTitle="Software Engineering Intern, Meta" subtitle="May&ndash;Aug 2021">
                            <p>
                                For my first internship with <a title="Meta Website" target="_blank" rel="noreferrer" href="https://about.meta.com">Meta</a>,
                                I was responsible for overhauling an outdated, deprecated storage and querying solution for nearby geospatial searches, which
                                was to be replaced with a new, internally developed system. This undertaking required seamless integration with the existing
                                infrastructure while maintaining efficiency and correctness.
                            </p>
                            <ul>
                                <li>Designed and implemented the new storage and querying solution for nearby geospatial searches, which involved
                                    programming in Hack for app-related tasks and creating SQL data pipelines to provide an efficient storage system</li>
                                <li>Conducted experiments with gradual rollout on the Facebook app to verify the efficiency and correctness of the new
                                    system</li>
                                <li>Ensured long-term sustainability by addressing the deprecation of the previous system, guaranteeing uninterrupted
                                    functionality for the Find Wi-Fi feature</li>
                            </ul>
                            <p>
                                During this internship, I worked in <a title="Hack Language Website" target="_blank" rel="noreferrer" href="https://hacklang.org/">Hack</a> and
                                SQL, using <a title="Mercurial Website" target="_blank" rel="noreferrer" href="https://www.mercurial-scm.org/">Mercurial</a> for
                                source control.
                            </p>
                        </DropdownSection>
                    </HeadingLevel>
                </HeadingLevel>
                <HeadingLevel>
                    <Heading>Computer Architecture Teaching Assistant</Heading>
                    <p>
                        In college, before I had enough experience for any indie game development or software engineering internships, I had the opportunity to
                        help other students through a difficult course in Computer Architecture.
                    </p>
                    <HeadingLevel>
                        <DropdownSection sectionTitle="Undergraduate Teaching Assistant, UT Computer Science Dept." subtitle="Jan 2021&ndash;May 2022">
                            <p>
                                Through the <a title="UT Computer Science Website" target="_blank" rel="noreferrer" href="https://www.cs.utexas.edu/">UT Computer Science Department</a>,
                                I helped students learn fundamental concepts in computer architecture, low-level data representation, memory management, and the
                                C programming language.
                            </p>
                            <ul>
                                <li>Conducted biweekly office hours, providing one-on-one assistance to an average of 20-30 students per session, including
                                    helping students troubleshoot coding issues and offering tailored guidance</li>
                                <li>Created and maintained repositories of C code exemplifying various concepts to share as helpful resources for students
                                    both in and outside of weekly discussion sections</li>
                                <li>Solidified my understanding of C programming, data types, and binary representation, allowing me to confidently explain
                                    complex concepts to unfamiliar students</li>
                            </ul>
                            <p>
                                By serving as an Undergraduate Teaching Assistant, I not only helped others succeed but also cultivated my own skills, making
                                me a proficient programmer with a deep understanding of low-level programming, data representation, and memory management.
                                These skills have not only benefited me in teaching but also make me a valuable asset as a programmer, particularly in C-like
                                languages such as C++, C#, and Java.
                            </p>
                        </DropdownSection>
                    </HeadingLevel>
                </HeadingLevel>
            </div>
        </HeadingLevel>
    </div>);
}