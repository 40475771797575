import './index.scss';

import { Heading } from "@ariakit/react";

const Header = ({ text }: { text: string }) => (
    <div className="top-bar">
        <div className="header-container">
            <Heading className="montserrat enormous-text">
                { text.toUpperCase() }
            </Heading>
        </div>
    </div>
);

export default Header;