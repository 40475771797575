import Header from '../Header';
import NoahKuhnLogo from '../../assets/images/NOAH_KUHN_logo.png';
import TTYPromo from '../../assets/images/tty-promo.png';
import PMANTitle from '../../assets/images/PUNCHERMAN-title.png';

import { HeadingLevel, Heading } from '@ariakit/react';

const Projects = () => (
    <div>
        <HeadingLevel>
            <Header text="projects" />
            <div className="main-content">
                <HeadingLevel>
                    <Heading>Games</Heading>
                    <HeadingLevel>
                        <Heading>
                            <a className="montserrat" title="Demo build of The Tedium and You on itch.io" target="_blank" rel="noreferrer" href="https://noahkuhn.itch.io/the-tedium-and-you">THE TEDIUM AND YOU</a> is
                            an experimental exploration of low&#8209;grade dread in a meaningless world.
                        </Heading>
                        <div className="image-paragraph-container">
                            {/* <div className="big-image">
                                <img className="fit-image" src={TTYPromo} alt="Low-fidelity bland office environment: yellowish walls, blue-gray carpeting, low-poly chairs and other office props scattered about. The text 'The Tedium and You' is superimposed in white in the top left corner." />
                            </div> */}
                            <p>
                                Getting points is easy: just walk back and forth across a boring hallway. But that likely won't hold your interest.
                                Leave the hallway and find that the more you see, the less you know; halls wrap in on themselves, rooms aren't
                                always where you expected them to be, and you just can't seem to escape the soulless corporate carpeting and ticking clock.
                            </p>
                            <img className="static-image" src={TTYPromo} alt="Low-fidelity bland office environment: yellowish walls, blue-gray carpeting, low-poly chairs and other office props scattered about. The text 'The Tedium and You' is superimposed in white in the top left corner." />
                            <ul>
                                <li className="montserrat">NOVEMBER 2021&ndash;PRESENT</li>
                                <li><span className="montserrat">SOLO DEVELOPER</span>: responsible for designing concept and rooms, programming all systems including procedural generation
                                    and antinarrative traversal, creating models and music, writing and recording dialogue, and most everything else</li>
                                <li><span className="montserrat">TOOLS & SKILLS UTILIZED</span>: Unity, C#, and Git</li>
                                <li>Not-necessarily-Euclidean procedural map generation allowing rooms to wrap in on each other, impossible loops to
                                    form, and rooms to change when player isn't looking</li>
                                <li>"Meandering" antinarrative system dynamically responding to in-game stimuli for contextually relevant dialogue</li>
                                <li>Preparing for an early access release on Steam</li>
                            </ul>
                        </div>
                    </HeadingLevel>
                    <HeadingLevel>
                        <Heading>
                            <a className="montserrat" title="PUNCHERMAN!: First Day on Steam" target="_blank" rel="noreferrer" href="https://store.steampowered.com/app/2364050/PUNCHERMAN_First_Day">PUNCHERMAN!</a> is
                            a comedy physics dad simulator.
                        </Heading>
                        <div className="image-paragraph-container">
                            <p>
                                PUNCHING IS ALL YOU KNOW. YOU'VE PUNCHED ALIENS, DEMONS, ZOMBIES&mdash;BUT WITH THESE THREATS GONE, YOU FACE YOUR GREATEST CHALLENGE YET: FAMILY LIFE.
                            </p>
                            <img className="static-image" src={PMANTitle} alt="In bright comic book colors: close shot of a very manly man's face and upper body, his fist extended toward the viewer, in front of an explosion. Text on the right reads 'PUNCHERMAN!' in all caps, also in front of an explosion." />
                            <ul>
                                <li className="montserrat">JANUARY 2022&ndash;MAY 2023</li>
                                <li><span className="montserrat">PROJECT LEAD</span>: designed and documented extensible class families for questing system and
                                    interactive objects, successfully facilitated smooth transition between two distinct development teams, and acted as a
                                    generalist to fill any cracks during both development cycles</li>
                                <li><span className="montserrat">TOOLS & SKILLS UTILIZED</span>: Unity, C#, and Git</li>
                                <li>Task-based questing system with support for updating and completing a variety of task types</li>
                                <li>Hierarchy of interactive objects with different behaviors upon being punched by player</li>
                                <li>Released on <a title="PUNCHERMAN!: First Day on Steam" target="_blank" rel="noreferrer" href="https://store.steampowered.com/app/2364050/PUNCHERMAN_First_Day">Steam</a> in
                                    April 2023 (as <em><strong>PUNCHERMAN!</strong>: First Day</em>)</li>
                            </ul>
                        </div>
                    </HeadingLevel>
                    <HeadingLevel>
                        <Heading>Want to see more of my game projects?</Heading>
                        <p>
                            These are just my two most recent major projects. Feel free to look
                            through <a title="my portfolio colletion on itch.io" target="_blank" rel="noreferrer" href="https://itch.io/c/2947077/portfolio">my itch portfolio collection</a> or
                            the rest of <a title="my itch.io page" target="_blank" rel="noreferrer" href="https://noahkuhn.itch.io/">my itch page</a>: it
                            has nearly all of my game projects since I started using Unity. You'll find them organized into categories including solo projects,
                            jam submissions, and coursework.
                        </p>
                    </HeadingLevel>
                </HeadingLevel>
                <HeadingLevel>
                    <Heading>Code</Heading>
                    <HeadingLevel>
                        <Heading>
                            <a className="montserrat" title="This website (homepage)" rel="noreferrer" href="/">NOAHKUHN.WEBSITE</a> is this website: my online
                            resume/portfolio/homepage.
                        </Heading>
                        <div className="image-paragraph-container">
                            <p>
                                They told me not to make my own website. "Just use a template," they said, "it'll be much easier and faster." I made a website
                                anyway, because I'm a computer scientist and reasoned that I knew enough TypeScript and React to make one. And here we are!
                            </p>
                            {/* <img className="static-image" src={NoahKuhnLogo} alt="The logo for this website: NOAH KUHN in very heavy and dark text, (they/them) underneath in thinner text of the same color, and teal accents." /> */}
                            <ul>
                                <li className="montserrat">JULY&ndash;OCTOBER 2023</li>
                                <li><span className="montserrat">SOLO DEVELOPER</span>: designed and developed website layout and CSS from scratch, created custom components,
                                    and utilized <a title="Ariakit website" target="_blank" rel="noreferrer" href="https://ariakit.org/">Ariakit</a> components to promote
                                    accessibility</li>
                                <li><span className="montserrat">TOOLS & SKILLS UTILIZED</span>: TypeScript, CSS, React, and Git</li>
                                <li>Design choices for accessibility:
                                    <ul>
                                        <li>Most text is set in <a title="Atkinson Hyperlegible on Google Fonts" target="_blank" rel="noreferrer" href="https://fonts.google.com/specimen/Atkinson+Hyperlegible">Atkinson Hyperlegible</a> at a fairly large size</li>
                                        <li>Simple, contrastive color palette for colorblindness</li>
                                        <li>Links change background color and grow on hover or focus, similar to <a title="WebAim article on formatting links for accessibility" target="_blank" rel="noreferrer" href="https://webaim.org/techniques/hypertext/link_text#appearance">WebAim</a></li>
                                        <li>Navigable with only keyboard tab and arrow keys</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </HeadingLevel>
                    <HeadingLevel>
                        <Heading>
                            <a className="montserrat" title="Solar System NOW" rel="noreferrer" href="https://noahkuhn.website/solarsystemnow/">SOLAR SYSTEM NOW</a> is
                            an approximated live view of the current mean anomalies of the planets of the Solar System.
                        </Heading>
                        <div className="image-paragraph-container">
                            <p>
                                I woke up one morning with the realization that I could use React and some orbital data to approximate the positions of the planets along
                                their orbital paths on a given day, then jammed out the concept in about 12 hours. I included some sliders for interactivity, as planets
                                move imperceptibly slow when the view is live.
                            </p>
                            <ul>
                                <li className="montserrat">SEPTEMBER 2023</li>
                                <li><span className="montserrat">SOLO DEVELOPER</span></li>
                                <li><span className="montserrat">TOOLS & SKILLS UTILIZED</span>: TypeScript, CSS, React, and Git</li>
                            </ul>
                        </div>
                    </HeadingLevel>
                    <HeadingLevel>
                        <Heading>Want to see more of my code projects?</Heading>
                        <p>
                            Admittedly, I have a few more projects to add to this section, but for now, feel free to look over
                            my <a title="my GitHub" target="_blank" rel="noreferrer" href="https://github.com/noah-kuhn/">GitHub</a> and <a title="my GitLab" target="_blank" rel="noreferrer" href="https://gitlab.com/nk_ultra">GitLab</a>.
                            I use GitHub less often, but most of my GitLab repos are private; in any case, you can see some of the stuff I've worked on in the past.
                        </p>
                    </HeadingLevel>
                </HeadingLevel>
            </div>
        </HeadingLevel>
    </div>
);

export default Projects;