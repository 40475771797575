import Header from '../Header';
import NoahKuhnHeadshot from '../../assets/images/NoahKuhn_Headshot_sq.jpg';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGears } from '@fortawesome/free-solid-svg-icons';
import { HeadingLevel, Heading } from '@ariakit/react';

const Homepage = () => (
    <div>
        <HeadingLevel>
            <Header text="howdy!" />
            <div className="main-content">
                <HeadingLevel>
                    <Heading>
                        <span className="montserrat">NOAH KUHN </span>is a programmer, game developer, and
                        <br />
                        general maker&#8209;of&#8209;things based in Austin, Texas.
                    </Heading>
                    <p>
                        With experience ranging from indie games to internal tools for Meta, Noah Kuhn is ready and eager to make cool things of any scope with you.
                    </p>
                    <div className="image-paragraph-container">
                        <img className="big-image" src={NoahKuhnHeadshot} alt="Headshot: that's my face!" />
                        <p>
                            Noah graduated with honors from the University of Texas at Austin, earning a Bachelor of Science in Computer Science. Additionally, they
                            earned a 19-hour certificate in Digital Arts and Media with an emphasis in Game Development. In short, they studied making computers do
                            cool things&hellip; a lot.
                        </p>
                        <p>
                            Their enthusiasm for programming games naturally led them to be an active member of UT Austin's Electronic Game Developers Society
                            (<a title="EGaDS! Website" target="_blank" rel="noreferrer" href="https://texasegads.wixsite.com/egads/about">EGaDS!</a>),
                            serving as its Main Events Officer in 2021-22 and its President in 2022-23. In these leadership positions, they worked with a dynamic
                            team to meet the organization's high-level objectives while also cultivating a thriving community of hundreds of student game developers.
                        </p>
                        <p>
                            Noah's diverse <Link to="/history" title="My work history">professional experience</Link> highlights their flexibility: they've worked
                            both in software and in games, in enormous code bases and in teams as small as three. While at Meta, they collaborated with software
                            engineers to provide solutions for both user-facing features and internal tools only used by other Meta developers. Working with smaller
                            teams, they've taken on responsibilities such as designing key systems and architecting class hierarchies to ensure project extensibility.
                        </p>
                    </div>
                    <HeadingLevel>
                        <Heading className="montserrat"><FontAwesomeIcon icon={faGears} /> SKILLS</Heading>
                        <p>
                            Noah's best programming languages are C#, C++, C, Java, TypeScript, and Hack. They also have experience with Python, SQL, HTML, JavaScript, and Lua.
                        </p>
                        <p>
                            Noah has over four years of experience working in Unity. They also have experience in Unreal and Godot.
                        </p>
                        <p>
                            In terms of development practices and methodologies, Noah has experience with agile development (scrum in particular), continuous integration, and rapid prototyping and iteration.
                        </p>
                    </HeadingLevel>
                </HeadingLevel>
            </div>
        </HeadingLevel>
    </div>
);

export default Homepage;