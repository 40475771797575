import './index.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Heading, Disclosure, DisclosureContent, useDisclosureStore } from '@ariakit/react';

interface DropdownSectionProps {
    sectionTitle: string;
    subtitle?: string;
    children: JSX.Element | JSX.Element[];
}

export default function DropdownSection (props: DropdownSectionProps){
    const disclosure = useDisclosureStore();

    return (
        <div className="dropdown-section-wrapper">
            <Disclosure store={disclosure} className="dropdown-section-button">
                <FontAwesomeIcon icon={faSquareCaretDown} />
                <div className="dropdown-section-header-text">
                    <Heading>{props.sectionTitle}</Heading>
                    <span>{props.subtitle}</span>
                </div>
            </Disclosure>
            <DisclosureContent store={disclosure} className="dropdown-section-content">
                {props.children}
            </DisclosureContent>
        </div>
    );
}